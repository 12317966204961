@import "10__settings/settings.colors";
@import "30__generic/generic.raleway";


.c-externalShare {
    font-family: "Raleway", Helvetica Neue, Helvetica, Arial, sans-serif;
    margin: 10px auto;
    max-width: 1080px;

    /* stylelint-disable */
    // we set element to display none in HTML so if css could not be loaded, element is not
    display: block !important;
    /* stylelint-enable */

    a {
        text-decoration: none;
        font-weight: normal;
    }


    h1 {
        color: var(--color-theme);
        margin-top: 0;
        margin-bottom: .25em;
        font-size: 170%;
        font-weight: 600;
        line-height: 1.15;
    }
}


.c-externalShare__container {

    background-color: #fff;
    border-radius: 3px;
    border: 1px solid var(--color-grey-light);
    box-sizing: border-box;
    overflow: hidden;

    padding: 1rem;

    &:hover,
    &:focus {
        border-color: var(--color-theme);
    }

}


.c-externalShare__img {

    position: relative;
    float: left;
    margin-right: 1rem;
    width: 33%;
    max-width: 200px;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

}


.c-externalShare__body {
    overflow: hidden;
    display: block;
}



.c-externalShare__text {
    color: #4A4A4A;
    line-height: 1.55;
    margin-top: 0;
    margin-bottom: 0;
}


@media (max-width: 420px) {

    .c-externalShare {
        margin: .5rem;

        h1 {
            font-size: 150%;
        }
    }

    .c-externalShare__img {

        float: none;
        margin-right: 0;
        width: 100%;
        max-width: 100%;

        margin-bottom: .25rem;

    }
}
