// colors



:root {
    --color-main: #3B5EBA;
    --color-main-bright: #418AEC;
    --color-main-light: #ECF1FF;
    --color-main-dark: #3B4B96;
    //--color-cat: #006A66;
    --color-cat: var(--color-main);
    //--color-cat-dark: #004E4A;
    --color-cat-dark: var(--color-main-dark);
    --color-cat-exp: #D63F2E;
    --color-cat-exp-dark: #B43C2D;
    --color-highlight: #ffd91f;
    --color-error: #FF0000;
    --color-error-rgb: 255, 0, 0;
    --color-grey: #4A4A4A;
    --color-grey-medium: #8E8E8E;
    --color-grey-light: #F0F0F0;
    --color-grey-dark: #3C3C3C;
    --color-bright: #FFFFFF;
    --color-dark: #000000;
    --color-opacity: .15;
    --color-theme: var(--color-main);
    --color-dropdown: var(--color-theme);
    --color-btn-primary: var(--color-bright);
    --color-btn-primary-background: var(--color-main);
    --color-btn-primary-border: var(--color-main);
    --color-btn-highlight: var(--color-main);
    --color-btn-highlight-hover: var(--color-highlight);
    --color-btn-highlight-background: var(--color-highlight);
    --color-btn-highlight-background-hover: var(--color-main);
    --color-btn-highlight-border: var(--color-highlight);
    //--color-highlight: #ffa500;
}



.t-main {
    --color-theme: var(--color-main);
    --color-theme-dark: var(--color-main-dark);
    --color-theme-rgb: 59, 94, 186;
}



.t-cat-exp {
    --color-theme: var(--color-cat-exp);
    --color-theme-rgb: 214, 63, 46;
    --color-theme-dark: var(--color-cat-exp-dark);
}


.t-inverted {
    --color-dropdown: var(--color-bright);
}
