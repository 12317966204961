/* raleway-regular - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    src:
        local("Raleway"),
        local("Raleway-Regular"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/raleway-v13-latin-regular.woff2") format("woff2"),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        url("../assets/fonts/raleway-v13-latin-regular.woff") format("woff");
}

/* raleway-italic - latin */
@font-face {
    font-family: "Raleway";
    font-style: italic;
    font-weight: 400;
    src:
        local("Raleway Italic"),
        local("Raleway-Italic"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/raleway-v13-latin-italic.woff2") format("woff2"),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        url("../assets/fonts/raleway-v13-latin-italic.woff") format("woff");
}

/* raleway-700 - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    src:
        local("Raleway Bold"),
        local("Raleway-Bold"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/raleway-v13-latin-700.woff2") format("woff2"),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        url("../assets/fonts/raleway-v13-latin-700.woff") format("woff");
}

/* raleway-800 - latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 800;
    src:
        local("Raleway ExtraBold"),
        local("Raleway-ExtraBold"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/raleway-v13-latin-800.woff2") format("woff2"),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        url("../assets/fonts/raleway-v13-latin-800.woff") format("woff");
}
